import React from 'react'
import PropTypes from 'prop-types'
import renderComponent from '../../utils/renderComponent'
import useAttributes from '../../utils/hooks/useAttributes'

import './Section.scss'

const Section = ({
  component = 'section',
  settings,
  children,
  inner,
  lang,
}) => {
  const { attributes } = useAttributes({
    elementClass: component + (inner ? ' inner' : ' layout'),
    settings,
  })

  return React.createElement(
    'section',
    attributes,
    children.map((child) => renderComponent({ ...child, lang })) || null
  )
}

Section.propTypes = {
  component: PropTypes.string,
  settings: PropTypes.object,
  children: PropTypes.array,
  inner: PropTypes.bool,
  lang: PropTypes.string,
}

export default Section
