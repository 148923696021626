import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import useAttributes from '../../utils/hooks/useAttributes'

import './Contact.scss'

import { useSiteData } from '../Context/SiteContext'

const regMail = /\S+@\S+\.\S+/
const regPhone = /[0-9]*\/*(\+49)*[ ]*(\([0-9]+\))*([ ]*(-|–)*[ ]*[0-9]+)*/

const Contact = ({ settings }) => {
  const [mounted, setMounted] = useState(true)
  const { attributes } = useAttributes({
    elementClass: 'contact',
    settings,
  })
  const [data, setData] = useState()
  const [branches, setBranches] = useState()
  const { state } = useSiteData()

  const formatLink = (text) => {
    const trimed = text.replace(/\s/g, '')
    if (regMail.test(trimed)) return `mailto:${trimed}`
    if (regPhone.test(trimed)) return `tel:${trimed}`
    return '#'
  }

  useEffect(() => {
    if (!mounted || !state) return
    const lang = state?.lang
    setData(state?.contact)
    setBranches(
      state.contact[`branches${lang === 'en' ? '_en' : ''}`] ||
        state.contact.branches ||
        []
    )
    return () => setMounted(false)
  }, [state])

  return data && branches ? (
    React.createElement(
      'div',
      attributes,
      <React.Fragment>
        {settings.address ? (
          <React.Fragment>
            <h5 className="company headline">{data.company}</h5>
            <div className="address">
              <div className="street">{data.street}</div>
              <div className="zipcity">
                <span className="zip">{data.zip}</span>{' '}
                <span className="city">{data.city}</span>
              </div>
            </div>
          </React.Fragment>
        ) : null}
        <div className="branches">
          {branches.map((branch) => (
            <div className="branch" key={branch.value.branch}>
              <div className="branch-name">{branch.value.branch}</div>
              <div className="phone">
                <a href={formatLink(branch.value.phone)}>
                  {branch.value.phone}
                </a>
              </div>
            </div>
          ))}
        </div>
        <div className="email">
          <a href={formatLink(data.email)}>{data.email}</a>
        </div>
      </React.Fragment>
    )
  ) : (
    <h5 className="company headline">
      {state?.lang === 'de' ? 'wird geladen...' : 'Loading...'}
    </h5>
  )
}

Contact.propTypes = {
  settings: PropTypes.object,
  address: PropTypes.bool,
}

export default Contact
