import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

import './styles/index.scss'

import Page from './components/Page/Page'
import { SiteProvider } from './components/Context/SiteContext'

const App = () => {
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route path="/*">
            <SiteProvider>
              <Page />
            </SiteProvider>
          </Route>
        </Switch>
      </Router>
      <div id="modal" />
    </div>
  )
}

export default App
