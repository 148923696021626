import React from 'react'
import PropTypes from 'prop-types'
import useAttributes from '../../utils/hooks/useAttributes'

import './Link.scss'

const Link = ({ settings }) => {
  const { text, url, target } = settings
  const { attributes } = useAttributes({
    elementClass: 'link',
    settings,
  })

  return React.createElement(
    'a',
    {
      ...attributes,
      href: url,
      target: target,
      title: text,
    },
    <span>{text}</span>
  )
}

Link.propTypes = {
  settings: PropTypes.object,
}

export default Link
