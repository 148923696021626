import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import './HeaderFnF.scss'

import Logo from '../Picture/Logo'

const HeaderFnF = () => {
  return (
    <header className={`fnf-header`}>
      <div className="content">
        <Link to="/">
          <div className="title">Female & Fierce Club</div>
          <div className="sub-logo">
            <div>by </div>
            <Logo
              settings={{
                type: 'logo',
                width: '98',
                height: '22',
              }}
            />
          </div>
        </Link>
      </div>
      <div className="divider" />
    </header>
  )
}

HeaderFnF.propTypes = {
  colored: PropTypes.bool,
  location: PropTypes.string,
}

export default HeaderFnF
