import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import useSort from '../../utils/hooks/useSort'

import './StickyLinks.scss'

import linkedin from '../../assets/icons/linkedin.svg'
import xing from '../../assets/icons/xing.svg'
import instagram from '../../assets/icons/instagram.svg'
import mail from '../../assets/icons/mail_white.svg'
import arrow from '../../assets/icons/arrow_up.svg'

import { useScrollPosition } from '../../utils/hooks/useScrollPosition'
import { useSiteData } from '../Context/SiteContext'

const StickyLinks = ({ show, jump, location }) => {
  const isFnF = useRef(false)
  const { sort } = useSort('order', 'asc')
  const [mounted, setMounted] = useState(true)
  const [showArrow, setShowArrow] = useState(false)
  const [menu, setMenu] = useState()
  const { state } = useSiteData()

  const src = { linkedin, xing, instagram, mail, arrow }

  useScrollPosition(
    (pos) => {
      setShowArrow(pos.currPos.y > window.innerHeight / 2)
    },
    [],
    null,
    true,
    500
  )

  const BackToTop = () => {
    const handleToTop = (evt) => {
      evt.preventDefault()
      window.scroll({
        top: 0,
        behavior: 'smooth',
      })
    }
    return (
      <li
        className="icon backtotop"
        key="backtotop"
        title={state?.lang === 'de' ? 'Zurück nach oben' : 'Back to the top'}
      >
        <div className="wrapper" onClick={handleToTop}>
          <img src={src.arrow} width={36} height={36} />
        </div>
      </li>
    )
  }

  const menuItems = (item) => (
    <li className="icon" key={item.icon} title={item.title}>
      <a
        href={isFnF.current ? item.fnf : item.url}
        target={item.target || '_blank'}
        title={item.icon}
        rel="nofollow"
      >
        <img src={src?.[item.icon] || 'mail'} width={36} height={36} />
      </a>
    </li>
  )

  const handleOnClick = (evt) => {
    evt.preventDefault()
    const target =
      document.querySelector('.contact')?.closest('.stage') || document.body
    const gap = target?.getBoundingClientRect().y || 100
    window.scroll({
      top: window.pageYOffset + gap,
      behavior: 'smooth',
    })
  }

  useEffect(() => {
    if (!mounted || !state) return

    const reordered = state?.sticky?.entries.sort(sort)
    setMenu(reordered)

    return () => setMounted(false)
  }, [state])

  useEffect(() => {
    isFnF.current =
      location.includes('fnf-club') ||
      window.location.hostname.includes('femaleandfierce') ||
      window.location.hostname.includes('192.168.')
  }, [])

  return show ? (
    <div className={`stickylinks${isFnF.current ? ' fnf' : ''}`}>
      <ul className="sticky">
        {showArrow ? <BackToTop /> : null}
        {jump ? (
          <li
            className="icon"
            key="contact"
            title={state?.lang === 'de' ? 'Kontaktieren' : 'Contacts'}
          >
            <div className="wrapper" onClick={handleOnClick}>
              <img src={src?.mail} width={36} height={36} />
            </div>
          </li>
        ) : null}
        {menu && menu.map((item) => menuItems(item))}
      </ul>
    </div>
  ) : null
}

StickyLinks.propTypes = {
  show: PropTypes.bool,
  jump: PropTypes.bool,
  location: PropTypes.string,
}

export default StickyLinks
