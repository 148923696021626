import React from 'react'
import PropTypes from 'prop-types'
import useAttributes from '../../utils/hooks/useAttributes'

import './ScrollLink.scss'

import arrow_down from '../../assets/icons/arrow_down.svg'
import arrow_up from '../../assets/icons/arrow_up.svg'

const ScrollLink = ({ settings }) => {
  const { direction } = settings || {}
  const { attributes } = useAttributes({
    elementClass: 'scrolllink',
    settings,
  })

  const src = {
    down: arrow_down,
    up: arrow_up,
  }

  const handleOnClick = (evt) => {
    evt.preventDefault()
    const target = evt.target.closest('.stage').nextElementSibling
    const gap = target.getBoundingClientRect().y
    window.scroll({
      top: window.pageYOffset + gap,
      behavior: 'smooth',
    })
  }

  return React.createElement(
    'button',
    {
      ...attributes,
    },
    <img
      src={src[direction]}
      onClick={handleOnClick}
      alt={`scroll_${direction}`}
    />
  )
}

ScrollLink.propTypes = {
  settings: PropTypes.object,
}

export default ScrollLink
