import React from 'react'
import PropTypes from 'prop-types'

const NotFound = ({ lang }) => {
  return (
    <div className="stage not-found">
      <div className="section layout">
        <h3 className="headline element">404 Error: Page Not Found</h3>
        <div className="copy element">
          {lang === 'de'
            ? 'Leider können wir die Seite nicht gefunden.'
            : 'Looks like the page or file you wanted went missing.'}
        </div>
      </div>
    </div>
  )
}

NotFound.propTypes = {
  lang: PropTypes.string,
}
export default NotFound
