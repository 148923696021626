import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import renderComponent from '../../utils/renderComponent'

import './UserConsent.scss'

import useStateWithSessionStorage from '../../utils/hooks/useStateWithSessionStorage'

const UserConsent = ({ context, lang }) => {
  const consentRef = useRef(null)
  const [closing, setClosing] = useState(false)

  const [value, setValue] = useStateWithSessionStorage('uconsent')

  const acceptCookie = () => {
    consentRef.current.classList.add('close')
    setClosing(true)
  }

  useEffect(() => {
    let timer
    if (closing) {
      timer = setTimeout(() => {
        setValue(true)
      }, 800)
    }
    return () => clearTimeout(timer)
  }, [closing])

  return (
    <div
      ref={consentRef}
      className={`user-consent ${value === 'true' ? '' : 'open'}`}
    >
      <div className="grid content">
        <div className="section inner">
          {context
            ? context.map((item) => renderComponent({ ...item, lang }))
            : null}
        </div>
        <div className="section inner">
          <button className="button" onClick={acceptCookie}>
            {lang === 'de' ? 'Einverstanden' : 'Accept'}
          </button>
        </div>
      </div>
    </div>
  )
}

UserConsent.propTypes = {
  context: PropTypes.array,
  lang: PropTypes.string,
}
export default UserConsent
