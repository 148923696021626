import Stage from './Stage/Stage'
import Section from './Stage/Section'
import Wide from './Stage/Wide'
import Grid from './Stage/Grid'
import Image from './Picture/Image'
import Logo from './Picture/Logo'
import Typograph from './Atoms/Typograph'
import Button from './Atoms/Button'
import Link from './Atoms/Link'
import Contact from './Atoms/Contact'
import Divider from './Atoms/Divider'
import ScrollLink from './Atoms/ScrollLink'
import Quotes from './Atoms/Quotes'
import Tabs from './Atoms/Tabs'
import Graphic from './Picture/Graphic'

export default {
  stage: Stage,
  section: Section,
  widesection: Wide,
  grid: Grid,
  image: Image,
  logo: Logo,
  heading: Typograph,
  text: Typograph,
  // html: RichText,
  button: Button,
  divider: Divider,
  link: Link,
  contact: Contact,
  scrolllink: ScrollLink,
  quotes: Quotes,
  tabs: Tabs,
  graphic: Graphic,
}
