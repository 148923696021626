import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import env from '../../utils/env'
import renderComponent from '../../utils/renderComponent'
import useAttributes from '../../utils/hooks/useAttributes'
import useWindowSize from '../../utils/hooks/useWindowSize'

import './Stage.scss'

import Markers from './Markers'

const { apiUrl, apiKey } = env

const Stage = ({ component, settings, children, lang }) => {
  const { backgroundColor, background } = settings

  const windowSize = useWindowSize()
  const { attributes } = useAttributes({
    elementClass: component,
    settings,
  })

  const [bgImageUrl, setBgImageUrl] = useState(null)

  const bgColor = backgroundColor ? backgroundColor : null

  const imageSize = {
    small: `Mobile`,
    medium: `Tablet`,
    large: `Desktop`,
  }

  useEffect(() => {
    if (!background?.path) return
    const imageUrl = background.path

    setBgImageUrl(
      `url(${apiUrl}/api/imagestyles/style/${imageSize[windowSize]}?token=${apiKey}&src=${imageUrl}&output=1)`
    )
  }, [windowSize, background])

  return React.createElement(
    'div',
    {
      ...attributes,
      style: {
        ...attributes.style,
        backgroundImage: bgImageUrl,
        backgroundColor: bgColor,
      },
    },
    <React.Fragment>
      <Markers color={settings.markers} />
      {children.map((child) => renderComponent({ ...child, lang })) || null}
    </React.Fragment>
  )
}

Stage.propTypes = {
  component: PropTypes.string,
  settings: PropTypes.object,
  children: PropTypes.array,
  lang: PropTypes.string,
}

export default Stage
