import { useLayoutEffect, useState } from 'react'

const sizeMap = [
  { value: 368, class: 'small' },
  { value: 1260, class: 'medium' },
  { value: 9999, class: 'large' },
]

const useWindowSize = () => {
  const [wSize, setWSize] = useState('small')

  const updateSize = () => {
    const label = sizeMap.find(
      (breakpoint) => breakpoint.value > window.innerWidth
    )
    setWSize(label ? label.class : 'small')
  }

  useLayoutEffect(() => {
    window.addEventListener('resize', updateSize)
    updateSize()
    return () => window.removeEventListener('resize', updateSize)
  }, [])

  return wSize
}

export default useWindowSize
