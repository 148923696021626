import React from 'react'
import PropTypes from 'prop-types'
import useAttributes from '../../utils/hooks/useAttributes'
import env from '../../utils/env'

import './Graphic.scss'

const { assetUrl } = env

const Graphic = ({ settings, name = '' }) => {
  const { graphic, width, height, top, left } = settings
  const { attributes } = useAttributes({
    elementClass: 'graphic-wrapper',
    settings,
  })

  return React.createElement(
    'picture',
    attributes,
    <React.Fragment>
      <img
        src={`${assetUrl}${graphic.path}`}
        width={width}
        height={height}
        style={
          top || left
            ? {
                position: 'absolute',
                top,
                left,
              }
            : {}
        }
        alt={`${graphic?.meta?.title || 'graphic'} - searchery.de`}
      />
      {name ? <figcaption>{name}</figcaption> : null}
    </React.Fragment>
  )
}

Graphic.propTypes = {
  settings: PropTypes.object,
  name: PropTypes.string,
}

export default Graphic
