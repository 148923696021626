import { useEffect, useState } from 'react'

const useAttributes = ({ elementClass, settings }) => {
  const [attributes, setAttributes] = useState({})
  const { id, class: className, style } = settings

  const buildStyles = (initStyle = '') => {
    const lines = initStyle.split('\n')

    return lines
      .map((line) => {
        const entry = line.split(':')
        return { [entry[0]]: entry[1] }
      })
      .reduce((acc, cur) => ({ ...acc, ...cur }), {})
  }

  const addStyles = (addStyle) => {
    setAttributes((prev) => ({
      ...prev,
      style: {
        ...prev.style,
        ...buildStyles(addStyle),
      },
    }))
  }

  useEffect(() => {
    setAttributes({
      id: id,
      className: `${elementClass}${className ? ' ' + className : ''}`,
      style: {
        ...buildStyles(style),
      },
    })
  }, [])

  return { attributes, addStyles }
}

export default useAttributes
