import React from 'react'
import PropTypes from 'prop-types'
import renderComponent from '../../utils/renderComponent'
import useAttributes from '../../utils/hooks/useAttributes'

import './Grid.scss'

const Grid = ({ component, settings, columns, wide, lang }) => {
  const { attributes } = useAttributes({
    elementClass: `${component} ${wide ? '' : 'layout'}`,
    settings,
  })

  return React.createElement(
    'div',
    attributes,
    columns.map((column) =>
      renderComponent({
        settings: column.settings,
        component: 'section',
        children: column.children,
        inner: true,
        lang: lang,
      })
    ) || null
  )
}

Grid.propTypes = {
  component: PropTypes.string,
  settings: PropTypes.object,
  columns: PropTypes.array,
  wide: PropTypes.bool,
  lang: PropTypes.string,
}

export default Grid
