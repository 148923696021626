import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import renderComponent from '../../utils/renderComponent'
import useAttributes from '../../utils/hooks/useAttributes'

import './Tabs.scss'

const Tabs = ({ settings }) => {
  const { tabs } = settings
  const { attributes } = useAttributes({
    elementClass: 'tabs grid',
    settings,
  })

  const closeAll = (tabs) => {
    const triggers = tabs.querySelectorAll('.open')
    const targets = tabs.querySelectorAll('.show')

    triggers.forEach((trigger) => trigger.classList.remove('open'))
    targets.forEach((target) => target.classList.remove('show'))
  }

  const handleOnClick = (evt) => {
    const parentSection = evt.target.closest('section')
    if (parentSection.id.includes('trigger')) {
      const target = parentSection.querySelector(
        `#target-${parentSection.id.split('-')[1]}`
      )
      if (parentSection.classList.contains('open')) {
        parentSection.classList.remove('open')
        target && target.classList.remove('show')
      } else {
        closeAll(parentSection.closest('.tabs'))
        parentSection.classList.add('open')
        target && target.classList.add('show')
      }
    }
  }

  useEffect(() => {}, [])

  return React.createElement(
    'div',
    {
      ...attributes,
      onClick: handleOnClick,
    },
    tabs.map((tab) =>
      renderComponent({
        settings: tab.settings,
        component: 'section',
        children: tab.children,
        inner: true,
      })
    )
  )
}

Tabs.propTypes = {
  settings: PropTypes.object,
}

export default Tabs
