import React from 'react'
import PropTypes from 'prop-types'
import useAttributes from '../../utils/hooks/useAttributes'

import './Logo.scss'

import logo_w from '../../assets/logo/logo_negative.svg'
import logo_c from '../../assets/logo/logo_color.svg'
import claim_w from '../../assets/logo/logo_claim_negative.svg'
import claim_c from '../../assets/logo/logo_claim_color.svg'
import full_w from '../../assets/logo/logo_claim_signet_negative.svg'
import full_c from '../../assets/logo/logo_claim_signet_color.svg'
import signet_w from '../../assets/logo/signet_negative.svg'
import signet_c from '../../assets/logo/signet_color.svg'

const Logo = ({ settings }) => {
  const { type = 'logo', color = true, width = 170, height } = settings
  const { attributes } = useAttributes({
    elementClass: 'logo',
    settings,
  })
  const calc = height ? height : width

  const src =
    {
      logo_w,
      logo_c,
      claim_w,
      claim_c,
      full_w,
      full_c,
      signet_w,
      signet_c,
    }?.[`${type}_${color ? 'c' : 'w'}`] || null

  const logoImage = attributes?.className?.includes('hero') ? (
    <h1>
      <img
        src={src}
        width={width}
        height={calc}
        alt="Searchery GmbH – Executive Search & Professional Search, Assessments, Talent & Leadership Advisory, Personalberatung"
        title="Searchery GmbH – Executive Search & Professional Search, Assessments, Talent & Leadership Advisory, Personalberatung"
      />
    </h1>
  ) : (
    <img src={src} width={width} height={calc} alt="logo - searchery.de" />
  )

  return React.createElement('div', attributes, logoImage)
}

Logo.propTypes = {
  settings: PropTypes.object,
}
export default Logo
