import React, { createContext, useContext, useEffect, useReducer } from 'react'
import PropTypes from 'prop-types'

import useFetch from '../../utils/hooks/useFetch'

const SiteContext = createContext()

const siteReducer = (state, action) => {
  switch (action.type) {
    case 'switchDE': {
      return {
        ...state,
        lang: 'de',
      }
    }
    case 'switchEN': {
      return {
        ...state,
        lang: 'en',
      }
    }
    case 'dataReady': {
      return {
        ...state,
        ...action.data,
      }
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}

const SiteProvider = ({ children }) => {
  const [state, dispatch] = useReducer(siteReducer, null)
  const value = { state, dispatch }
  const { status: stickyStatus, data: stickyData } = useFetch(
    '/collections/get/sticky'
  )
  const { status: contactStatus, data: contactData } = useFetch(
    '/singletons/get/contacts'
  )
  const { status: pageStatus, data: pageData } = useFetch(
    '/collections/get/page'
  )
  const { status: menuStatus, data: menuData } = useFetch(
    '/collections/get/menu'
  )
  const { status: footerStatus, data: footerData } = useFetch(
    '/collections/get/footer'
  )

  useEffect(() => {
    const isCompleted = [
      stickyStatus,
      contactStatus,
      pageStatus,
      menuStatus,
      footerStatus,
    ].every((status) => status === 'fetched')
    if (isCompleted) {
      dispatch({
        type: 'dataReady',
        data: {
          sticky: stickyData,
          contact: contactData,
          page: pageData,
          menu: menuData,
          footer: footerData,
          lang: 'de',
          title: contactData?.title,
        },
      })
    }
  }, [stickyStatus, contactStatus, pageStatus, menuStatus, footerStatus])

  return <SiteContext.Provider value={value}>{children}</SiteContext.Provider>
}

SiteProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
}

const useSiteData = () => {
  const context = useContext(SiteContext)
  if (context === undefined) {
    throw new Error('useSiteData must be used within SiteProvider scope.')
  }
  return context
}

export { SiteProvider, useSiteData }
