import React from 'react'
import PropTypes from 'prop-types'

import './BurgerButton.scss'

const BurgerButton = ({ menuOpen, toggleMenuOpen, colored }) => {
  return (
    <div
      className={`burgerButton ${menuOpen ? 'close' : 'burger'}${
        colored ? ' colored' : ''
      }`}
      onClick={toggleMenuOpen}
    >
      <div />
      <div />
      <div />
    </div>
  )
}

BurgerButton.propTypes = {
  menuOpen: PropTypes.bool,
  toggleMenuOpen: PropTypes.func,
  colored: PropTypes.bool,
}

export default BurgerButton
