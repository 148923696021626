import React from 'react'
import PropTypes from 'prop-types'
import useAttributes from '../../utils/hooks/useAttributes'
import env from '../../utils/env'

import './Image.scss'

const { assetUrl } = env

const Image = ({ settings, name = '' }) => {
  const { image, width, height } = settings
  const { attributes } = useAttributes({
    elementClass: 'image-wrapper',
    settings,
  })

  return React.createElement(
    'picture',
    attributes,
    <React.Fragment>
      <img
        src={`${assetUrl}${image.path}`}
        width={width}
        height={height}
        alt={`${image?.meta?.title || 'image'} - searchery.de`}
      />
      {name ? <figcaption>{name}</figcaption> : null}
    </React.Fragment>
  )
}

Image.propTypes = {
  settings: PropTypes.object,
  name: PropTypes.string,
}

export default Image
