import React from 'react'
import PropTypes from 'prop-types'
import useAttributes from '../../utils/hooks/useAttributes'

import './Typograph.scss'

const Typograph = ({ settings }) => {
  const { text, tag } = settings
  const { attributes } = useAttributes({
    elementClass: tag ? 'headline element' : 'copy element',
    settings,
  })

  return React.createElement(tag || 'div', {
    ...attributes,
    dangerouslySetInnerHTML: { __html: text },
  })
}

Typograph.propTypes = {
  settings: PropTypes.object,
}

export default Typograph
