import React, { useState, useEffect } from 'react'
import renderComponent from '../../utils/renderComponent'
import UserConsent from '../Atoms/UserConsent'

import './Footer.scss'

import Logo from '../Picture/Logo'
import { useSiteData } from '../Context/SiteContext'
import getIntlData from '../../utils/getIntlData'

const Footer = () => {
  const [mounted, setMounted] = useState(true)
  const [menu, setMenu] = useState([])
  const { state } = useSiteData()

  useEffect(() => {
    if (!mounted || !state) return

    setMenu(
      getIntlData(
        state.footer.entries.filter((entry) => entry.title === 'Footer')[0],
        'layout',
        state?.lang
      )
    )
    return () => setMounted(false)
  }, [state])

  return (
    <React.Fragment>
      <footer className="footer">
        <Logo settings={{ type: 'signet', color: true, width: '69' }} />

        {menu ? (
          <div className="copyright">
            {menu.map((item) =>
              renderComponent({ ...item, lang: state?.lang })
            )}
          </div>
        ) : (
          <div className="copyright">&copy; 2021 Searchery GmbH.</div>
        )}
      </footer>
      {menu ? (
        <UserConsent
          context={
            state?.footer?.entries.filter(
              (entry) => entry.title === 'UserConsent'
            )?.[0]?.layout || null
          }
          lang={state?.lang}
        />
      ) : null}
    </React.Fragment>
  )
}

export default Footer
