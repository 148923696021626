import React from 'react'
import PropTypes from 'prop-types'

import './Markers.scss'

const Markers = ({ color = 'transparent', fixed = false }) => {
  return (
    <div className={`markers ${color}${fixed ? ' fixed' : ''}`}>
      <div />
      <div />
      <div />
    </div>
  )
}

Markers.propTypes = {
  color: PropTypes.string,
  fixed: PropTypes.bool,
}

export default Markers
