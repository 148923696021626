import React from 'react'
import PropTypes from 'prop-types'
import useAttributes from '../../utils/hooks/useAttributes'

import './Divider.scss'

const Divider = ({ settings }) => {
  const { attributes } = useAttributes({
    elementClass: 'Divider',
    settings,
  })

  return React.createElement('div', {
    ...attributes,
  })
}

Divider.propTypes = {
  settings: PropTypes.object,
}

export default Divider
