import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import useSort from '../../utils/hooks/useSort'
import { Link } from 'react-router-dom'

import './Header.scss'

import Logo from '../Picture/Logo'
import BurgerButton from './BurgerButton'
import { useSiteData } from '../Context/SiteContext'
import getIntlData from '../../utils/getIntlData'
import HeaderFnF from './HeaderFnF'

const Header = ({ colored, location }) => {
  const { sort } = useSort('order', 'asc')
  const [mounted, setMounted] = useState(true)
  const [menuOpen, setMenuOpen] = useState(false)
  const [menu, setMenu] = useState(null)
  const [legal, setLegal] = useState(null)
  const { state, dispatch } = useSiteData()

  const menuItems = (item) => (
    <li key={item.order}>
      <Link
        className={
          getIntlData(item, 'url', state?.lang) === location ? 'active' : ''
        }
        to={getIntlData(item, 'url', state?.lang)}
      >
        {getIntlData(item, 'title', state?.lang)}
      </Link>
    </li>
  )

  const toggleMenuOpen = () => {
    setMenuOpen((current) => !current)
  }

  useEffect(() => {
    if (!mounted || !state) return

    const reordered = state.menu.entries
      .filter((entry) => entry.order < 90)
      .sort(sort)
    setMenu(reordered)

    setLegal(state.menu.entries.filter((entry) => entry.order > 89))

    return () => setMounted(false)
  }, [state])

  if (
    location.includes('fnf-club') ||
    window.location.hostname.includes('femaleandfierce') ||
    window.location.hostname.includes('192.168.')
  )
    return <HeaderFnF />

  return menu ? (
    <header
      className={`${colored ? 'colored' : ''}${menuOpen ? ' open' : ' close'}`}
    >
      <div className="content">
        <Link to="/" onClick={() => setMenuOpen(false)}>
          <Logo
            settings={{
              type: 'logo',
              color: menuOpen || colored,
              width: '173',
              height: '39',
            }}
          />
        </Link>
        <ul className="language">
          <li
            className={`de${state?.lang === 'de' ? ' active' : ''}`}
            onClick={() => dispatch({ type: 'switchDE' })}
          >
            DE
          </li>
          <li>|</li>
          <li
            className={`en${state?.lang === 'en' ? ' active' : ''}`}
            onClick={() => dispatch({ type: 'switchEN' })}
          >
            EN
          </li>
        </ul>
        <BurgerButton
          colored={colored}
          menuOpen={menuOpen}
          toggleMenuOpen={toggleMenuOpen}
        />
        <div className="drawer">
          <ul className="menu" onClick={() => setMenuOpen(false)}>
            {menu && menu.map((item) => menuItems(item))}
          </ul>
          {menuOpen && <Logo settings={{ type: 'signet' }} />}
          {menuOpen && (
            <div className="legal-links">
              {legal.map((item, idx) => (
                <React.Fragment key={idx}>
                  <Link
                    key={item.order}
                    to={getIntlData(item, 'url', state?.lang)}
                  >
                    {getIntlData(item, 'title', state?.lang)}
                  </Link>
                  {legal.length - 1 > idx && ` | `}
                </React.Fragment>
              ))}
            </div>
          )}
        </div>
      </div>
      <div className="divider" />
    </header>
  ) : null
}

Header.propTypes = {
  colored: PropTypes.bool,
  location: PropTypes.string,
}

export default Header
