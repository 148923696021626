import React from 'react'
import PropTypes from 'prop-types'
import useAttributes from '../../utils/hooks/useAttributes'

import './Button.scss'

const Button = ({ settings }) => {
  const { text, url } = settings
  const { attributes } = useAttributes({
    elementClass: 'button element',
    settings,
  })

  return React.createElement(
    'button',
    {
      ...attributes,
      href: url,
    },
    <span>{text}</span>
  )
}

Button.propTypes = {
  settings: PropTypes.object,
}

export default Button
