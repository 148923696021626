import React from 'react'
import PropTypes from 'prop-types'
import useAttributes from '../../utils/hooks/useAttributes'

import './Quotes.scss'

const Quotes = ({ settings }) => {
  const { body, source, url } = settings
  const { attributes } = useAttributes({
    elementClass: 'quotes',
    settings,
  })

  return React.createElement(
    'div',
    {
      ...attributes,
    },
    <React.Fragment>
      <div className="quote-body">{body}</div>
      <div className="source">
        <a rel="noreferrer" href={url} target="_blank">
          {source}
        </a>
      </div>
    </React.Fragment>
  )
}

Quotes.propTypes = {
  settings: PropTypes.object,
}

export default Quotes
