import React, { useState, useEffect, useRef } from 'react'
import { useLocation } from 'react-router'
import { Helmet, HelmetProvider } from 'react-helmet-async'

import './Page.scss'
import './fnf.scss'

import ReactLoading from 'react-loading'
import Header from '../Layout/Header'
import Footer from '../Layout/Footer'
import StickyLinks from '../Layout/StickyLinks'
import Markers from '../Stage/Markers'
import NotFound from '../Page/404'
import renderComponent from '../../utils/renderComponent'

import { useSiteData } from '../Context/SiteContext'
import getIntlData from '../../utils/getIntlData'

const Page = () => {
  const currentLocation = useRef(null)
  const location = useLocation()
  const [lang, setLang] = useState('de')
  const [pageContents, setPageContents] = useState([])
  const [content, setContent] = useState([])
  const [subtitle, setSubtitle] = useState('')
  const [title, setTitle] = useState('')
  const [meta, setMeta] = useState({})
  const [isHome, setIsHome] = useState(true)
  const [linkedData, setLinkedData] = useState(null)

  const { state } = useSiteData()

  const startPage = window.location.hostname.includes('searchery')
    ? 'home'
    : 'fnf-club'
  currentLocation.current = location.pathname.split('/')[1] || startPage

  const injectMeta = (data) => {
    return data
      ? Object.entries(data).map((entry) => {
          return entry[0] === 'title' ? (
            <title key={entry[0]}>{entry[1]}</title>
          ) : (
            <meta key={entry[0]} name={entry[0]} content={[entry[1]]} />
          )
        })
      : null
  }

  useEffect(() => {
    if (!state) return
    setPageContents(state.page.entries)
    setTitle(startPage === 'fnf-club' ? 'Female & Fierce Club' : state?.title)
    setLang(state?.lang)
  }, [state])

  useEffect(() => {
    if (!pageContents) return
    const context = pageContents.filter((entry) =>
      [entry.title_slug, entry.title_de_slug, entry.title_en_slug].includes(
        currentLocation.current
      )
    )

    const modifiedDate = (ms) => {
      return new Date(ms).toISOString()
    }

    const currentContent = getIntlData(context?.[0] || {}, 'layout', lang)
    setContent(currentContent)
    setIsHome(['home', 'fnf-club'].includes(context?.[0]?.title_slug) || false)
    if (currentContent) {
      setSubtitle(getIntlData(context[0], 'title', lang) || '')
      setMeta(getIntlData(context[0], 'metadata', lang) || {})
      setLinkedData({
        '@context': 'https://schema.org/',
        '@type': 'ProfessionalService',
        name: subtitle ? subtitle + ' - ' + title : title,
        author: {
          '@type': 'ProfessionalService',
          name: 'Searchery GmbH',
        },
        datePublished: modifiedDate(context[0]._modified * 1000),
        description: getIntlData(context[0], 'metadata', lang)?.description,
      })
    }
  }, [pageContents, location, lang])

  return (
    <HelmetProvider>
      {content && (
        <Helmet>
          <script type="application/ld+json">
            {JSON.stringify(linkedData)}
          </script>
          <title>
            {subtitle ? `${subtitle} - ` : ''}
            {title}
          </title>
          {injectMeta(meta)}
          <link
            rel="canonical"
            href={`https://${window.location.hostname}${location.pathname}`}
          />
        </Helmet>
      )}
      {content ? (
        <React.Fragment key={location.key}>
          <Header
            key={`header_${lang}`}
            colored={!isHome}
            location={currentLocation.current}
          />
          {!isHome && content ? <Markers color="petrol" fixed /> : null}
          <div className="content-wrapper">
            <main key={location.key} className={`${currentLocation.current}`}>
              {content ? (
                content.map((element) => renderComponent({ ...element, lang }))
              ) : (
                <NotFound lang={lang} />
              )}
            </main>
            <Footer key={`footer_${lang}`} lang={lang} />
          </div>
          <StickyLinks
            key={`sticky_${lang}`}
            show={
              !['impressum', 'datenschutz'].includes(currentLocation.current)
            }
            jump={[
              'home',
              'ansatz',
              'leistungen',
              'approach',
              'services',
              'fnf-club',
            ].includes(currentLocation.current)}
            location={currentLocation.current}
          />
        </React.Fragment>
      ) : (
        <div className="loader">
          <ReactLoading
            type="spin"
            color="currentColor"
            width={96}
            height={96}
          />
        </div>
      )}
    </HelmetProvider>
  )
}

export default Page
