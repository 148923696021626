import React from 'react'
import Components from '../components'
import { nanoid } from 'nanoid'

export default (block) => {
  const blockId = block?.settings?.id || nanoid()

  if (typeof Components[block.component] !== 'undefined') {
    const pbBlock = React.createElement(
      Components[block.component],
      {
        key: blockId,
        name: block.name,
        component: block.component,
        settings: block.settings,
        columns: block.columns,
        onclick: block.onclick,
        inner: block.inner,
        wide: block.wide,
        lang: block.lang,
      },
      block.children
    )
    return pbBlock
  }

  return React.createElement(
    () => (
      <div
        key={blockId}
        className="defaultBlock"
        style={{ width: '80vw', margin: '0 auto' }}
      >
        <hr />
        <pre>
          The component <b>{block.component}</b> has not been created yet.
        </pre>
        <hr />
      </div>
    ),
    { key: blockId }
  )
}
