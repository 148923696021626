const useSort = (propName = 'order', order = 'asc') => {
  const sort = (a, b) => {
    switch (order) {
      case 'asc':
        return Number(a[propName]) - Number(b[propName])
      case 'desc':
        return Number((b[propName] = Number(a[propName])))
      default:
        return a > b
    }
  }

  return { sort }
}

export default useSort
