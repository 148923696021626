import { useEffect, useRef, useState } from 'react'

import env from '../../utils/env'

const { apiUrl, apiKey } = env

const useFetch = (url, lang, location) => {
  const cache = useRef({})
  const [status, setStatus] = useState('idle')
  const [data, setData] = useState([])

  const requestUrl = `${apiUrl}/api${url}?token=${apiKey}${
    lang ? '&lang=' + lang : ''
  }`

  useEffect(() => {
    if (!url) return
    const fetchData = async () => {
      setStatus('fetching')
      if (cache.current?.[`${url}_${lang}`]) {
        const data = cache.current[`${url}_${lang}`]
        setData(data)
        setStatus('fetched')
      } else {
        const response = await fetch(requestUrl)
        const data = await response.json()
        cache.current[`${url}_${lang}`] = data // set response in cache;
        setData(data)
        setStatus('fetched')
      }
    }
    fetchData()
  }, [url, location, lang])

  return { status, data }
}

export default useFetch
