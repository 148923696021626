import React from 'react'
import PropTypes from 'prop-types'
import renderComponent from '../../utils/renderComponent'
import useAttributes from '../../utils/hooks/useAttributes'

import './Wide.scss'

const Wide = ({ component = 'section', settings, children, lang }) => {
  const { attributes } = useAttributes({
    elementClass: `${component} content ${settings.position}`,
    settings,
  })

  return React.createElement(
    'section',
    attributes,
    children.map((child) => renderComponent({ ...child, wide: true, lang })) ||
      null
  )
}

Wide.propTypes = {
  component: PropTypes.string,
  settings: PropTypes.object,
  children: PropTypes.array,
  inner: PropTypes.bool,
  lang: PropTypes.string,
}

export default Wide
